<template>
	<div style="background: #f5f5f5;">
		<qy-top-nav title="订单统计" />
		<div class="flex-between" style="background-color: #fff;position: fixed;top:46px;width: 100%;z-index: 10;;">
			<van-tabs v-model="status" @change="refresh" style="flex-grow: 1;">
				<van-tab title="待发货" name="1"></van-tab>
				<van-tab title="待签收" name="2"></van-tab>
				<van-tab title="已完成" name="3"></van-tab>
				<!-- <van-tab title="退货中" name="4"></van-tab> -->
			</van-tabs>
			<span style="padding-right: 20px;font-size: 15px;position: relative;top:-1px;"
				@click="showCondition=true"><van-icon name="filter-o" class="prt2" size="15" /> 筛选</span>
		</div>
		<div style="height: 50px;"> </div>
		<div :style="{height: containerHeight}">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="100" @load="loadMore()">

				<div v-if="dataList.length" v-for="(order,orderIndex) in dataList" @click="openDetail(order.orderId)"
					style="border-radius: 6px; background: #fff; margin: 10px; padding-bottom: 10px;">
					<template v-if="order.goodsList&&order.goodsList.length>0">
						<div v-if="isProxyOrder"
							style="font-size: 16px; font-weight: bold; padding: 12px 0;text-align:center;border-bottom:1px solid #ededed; margin-bottom: 10px; color: red; ">
							<i class="van-icon van-icon-manager prt2" /> {{order.userName}}({{order.userPhone}})
						</div>
						<div
							style="line-height: 50px;font-size: 18px;font-weight: bold;color: #333;padding-left: 5px;color: red;;">
							<i class="van-icon van-icon-send-gift prt2" /> {{order.shopName||'平台'}}
							<!-- <i class="van-icon van-icon-arrow prt2" /> -->
							<span
								style="float:right;color:orange;margin-right: 20px;">{{statusDesc[order.status]}}</span>
						</div>
						<div style="border-top:1px solid #ededed;padding-top: 6px;">
							<div v-for="(item,goodsIndex) in order.goodsList" :key="goodsIndex"
								style="border-bottom: 1px solid #ededed;padding-left:10px;padding-top:6px;display: flex; align-items: center; position: relative; padding-bottom: 10px;">
								<div style="flex-shrink: 0; margin: 0 10px ;">
									<img :src="getImgUrl(item.goodsLogo)" alt=""
										style="width: 90px; height: 90px; border-radius: 5px;">
								</div>

								<div class="" style="flex-grow: 1;">
									<div>
										<div>{{item.goodsName}} </div>
										<div style="color: #666;  padding: 8px 2px; margin-top: 0px;">
											{{getInstanceDesc(item,false)}}
										</div>
										<div style="margin: 0px; overflow: hidden; padding: 5px 0px;">
											<span class="goodsPrice"
												style="float: left; right: 10px; display: block; font-size: 14px; color: #c00;color: red; font-weight: bold;"><b>￥</b>{{qy.formatMoney(item.goodsDealerPrice > 0 ? item.goodsDealerPrice : item.goodsPrice)}}</span>
											<span style="margin-left: 12px; color: #999;">
												<i class="van-icon van-icon-cross prt2" />{{item.goodsNum}}
											</span>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="flex-between"
							style="font-size: 14px;font-weight: bold;letter-spacing: 1px;padding-top:10px;padding-right: 10px;color: red;align-items: center;">
							<span style="color: #333;padding-left: 12px;"> <van-icon name="tosend" class="prt2"
									size="16" /> {{order.createTime}}</span>
							<span><span style="font-size: 12px"> ¥</span>{{qy.formatMoney(order.orderMoney)}}</span>
						</div>

					</template>
				</div>
			</van-list>
			<div style="height: 120px;"></div>
		</div>
		<div style="position: fixed;bottom: 0;background-color: #fff;border-radius: 6px 6px 0 0;width: 100%;">
			<table class="statisticsTb" cellspacing="0">
				<thead>
					<tr>
						<th></th>
						<th>待发货</th>
						<th>待签收</th>
						<th>已完成</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>单数：</td>
						<td>{{statisticsData.noSendOrderNum}}</td>
						<td>{{statisticsData.noSignedOrderNum}}</td>
						<td>{{statisticsData.signedOrderNum}}</td>
					</tr>
					<tr>
						<td>商品(件)：</td>
						<td>{{statisticsData.noSendGoodsNum}}</td>
						<td>{{statisticsData.noSignedGoodsNum}}</td>
						<td>{{statisticsData.signedGoodsNum}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<van-overlay :show="showCondition" style="z-index: 10;" />
		<div class="qy-panel" v-show="showCondition" style="">
			<div class="qy-panel-title" style="">查询条件 <van-icon name="close" size="24"
					style="position: absolute;right: 15px;top:11px;;" @click="showCondition=false" /></div>
			<div class="qy-panel-body">
				<div class="flex mt6">
					<span class="control-label">客户名:</span>
					<input v-model="queryParams.userName" class="form-control" style="flex-grow: 1;" />
				</div>
				<div class="flex mt12">
					<span class="control-label">客户电话:</span>
					<input v-model="queryParams.userPhone" class="form-control" style="flex-grow: 1;" />
				</div>
				<div class="flex mt6">
					<span class="control-label">提货人:</span>
					<input v-model="queryParams.consigneeName" class="form-control" style="flex-grow: 1;" />
				</div>
				<div class="flex mt12">
					<span class="control-label">提货电话:</span>
					<input v-model="queryParams.consigneePhone" class="form-control" style="flex-grow: 1;" />
				</div>
				<div class="mt12">
					<span class="control-label">订单日期:</span>
					<span class="">
						<input v-model="queryParams.beginTime" @focus="openCalendar(1)" class="form-control"
							placeholder="开始日期" /> -
						<input v-model="queryParams.endTime" @focus="openCalendar()" class="form-control"
							placeholder="结束日期" />
					</span>
				</div>
			</div>
			<div class="qy-panel-bottom" style="">
				<van-button type="danger" plain @click="resetCondition()">重置</van-button>
				<van-button type="warning" @click="onConditionConfirm()">确定</van-button>
			</div>
		</div>
		<van-calendar v-model="showCalendar" :min-date="date.diffDay(-365*2)" :max-date="new Date()"
			@select="onConfirmCalendar" :show-confirm="false" />
	</div>
</template>
<script type="text/javascript">
	import request from "@/utils/request";
	export default {
		name: "orderList",
		components: {},

		data() {
			return {
				orderId: null,
				containerHeight: 0,
				totalPrice: 0,
				status: 1,
				statusDesc: {
					0: "待付款",
					1: "待发货",
					2: "待签收",
					3: "已完成",
					4: "退货处理中",
					5: "已退货",
					11: "已取消",
					12: "已超时"
				},
				balanceInfo: {},
				dataList: [],
				total: 0,
				disabled: false,
				payType: 0,
				useBalance: 0,
				showPayPanel: false,
				useBalance: false,
				isProxyOrder: 0,
				statisticsData: {
					"noSignedOrderNum": 0,
					"noSendOrderNum": 0,
					"totalGoodsNum": 0,
					"signedGoodsNum": 0,
					"noSendGoodsNum": 0,
					"totalOrderNum": 0,
					"signedOrderNum": 0,
					"noSignedGoodsNum": 0
				},
				isInWxMini: false,
				loading: false,
				finished: false,
				pageNum: 1,
				pageSize: 10,
				showCondition: false,
				showCalendar: false,
				isBeginTimeCalendar: false,
				
				queryParams: {
					userName: "",
					userPhone: "",
					consigneeName: "",
					consigneePhone: "",
					beginTime: "",
					endTime: ""
				}
			}
		},
		created() {
			if (top && top.location.href != location.href) {
				this.Toast.success(top.location.href)
				top.location.href = location.href;
			}
			this.resetCondition();
			if (this.isProxy() && this.getPageUrl().indexOf("proxy") > -1) {
				this.isProxyOrder = 1;
			}
			//console.log(document.documentElement.clientHeight)
			this.containerHeight = document.documentElement.clientHeight - 120 + "px";

			// this.refresh();

		},
		methods: {
			fetchBalance() {
				request.post("/api/balance").then(rs => {
					this.balanceInfo = rs.data;
				})
			},
			refresh() {
				if (this.loading) {
					return;
				}
				this.pageNum = 1;
				this.dataList = [];
				this.finished = false;
				this.loadMore();
			},
			loadMore() {
				/* if (this.loading) {
					return;
				} else {
					this.loading = true;
				} */
				let status = this.status >= 0 ? this.status : "";
				this.doPost("/api/order/statisticsOrder", {
					...this.queryParams,
					status: status,
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}, rs => {
					let data = rs.data;
					this.loading = false;
					if (data.rows.length < this.pageSize || data.total <= (this.pageNum - 1) * this.pageSize) {
						this.finished = true;
					}
					this.pageNum++;
					this.dataList = this.dataList.concat(data.rows);
					this.statisticsData = data.otherData;
				})
			},

			openDetail(orderId) {
				this.$router.push({
					path: "/orderDetail",
					query: {
						orderId: orderId
					}
				})
			},
			resetCondition() {
				this.queryParams.beginTime = this.date.formatDate(this.date.diffDay(-30));
				this.queryParams.endTime = this.date.formatDate(new Date());
				this.queryParams.consigneeName = "";
				this.queryParams.consigneePhone = "";
				this.queryParams.userName = "";
				this.queryParams.userPhone = "";
				this.showCondition=false;
				this.refresh();
			},
			onConditionConfirm(){
				this.showCondition=false;
				this.refresh();
			},
			openCalendar(isBeginTimeCalendar) {
				this.isBeginTimeCalendar = !!isBeginTimeCalendar;
				this.showCalendar = true;
			},
			onConfirmCalendar(d) {
				if (this.isBeginTimeCalendar) {
					this.queryParams.beginTime = this.date.formatDate(d)
				} else {
					this.queryParams.endTime = this.date.formatDate(d)
				}
				this.showCalendar = false;
				
			}
		},
		mounted() {
			// console.log(document.documentElement.clientHeight)
			this.containerHeight = document.documentElement.clientHeight - 120 + "px";
		},
	}
</script>
<style type="text/css" scoped="">

</style>